import React, { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import { getAuthHeaders } from './api-authorization/getAuthHeaders';

export function Home({ handleFetchException }) {
    const [topPanelClass, setTopPanelClass] = useState('div-home-panel-top div-home-panel-disabled')
    const [bottomPanelClass, setBottomPanelClass] = useState('div-home-panel-bottom div-home-panel-disabled')
    const [responseObject, setResponseObject] = useState(null)
    const [redirect, setRedirect] = useState(null)

    useEffect(() => {
        getState();
    }, [])

    async function getState() {
        const headers = await getAuthHeaders('application/json');
        const requestOptions = {
            method: 'GET',
            headers: headers
        };
        fetch(window.config.apiURL + "PersonnelAttendance/GetFirstPersonnelAttendance", requestOptions)
            .then(response => {
                if (!response.ok) { throw response }
                return response.json()
            })
            .then(data => {
                setResponseObject(data)
                if (data.IsCheckin && data.IsCheckout) {
                    setTopPanelClass('div-home-panel-top div-home-panel-active');
                    setBottomPanelClass('div-home-panel-bottom div-home-panel-disabled');
                } else if (data.IsCheckin && !data.IsCheckout) {
                    setTopPanelClass('div-home-panel-top div-home-panel-disabled');
                    setBottomPanelClass('div-home-panel-bottom div-home-panel-active');
                } else if (!data.IsCheckin && !data.IsCheckout) {
                    setTopPanelClass('div-home-panel-top div-home-panel-active');
                    setBottomPanelClass('div-home-panel-bottom div-home-panel-disabled');
                }
            })
            .catch(response => {
                handleFetchException(response);
            });
    }

    function checkinClick() {
        if (responseObject != null && ((responseObject.IsCheckin && responseObject.IsCheckout) || (!responseObject.IsCheckin && !responseObject.IsCheckout)))
            setRedirect('/checkin')
    }

    function checkoutClick() {
        if (responseObject != null && responseObject.IsCheckin && !responseObject.IsCheckout)
            setRedirect("/checkout?ID=" + responseObject.ID)
    }

    if (redirect) {
        return <Redirect to={redirect} />
    } else {
        return (
            <div className="div-home-panel">
                <div className={topPanelClass} onClick={checkinClick}>
                    Check-in
                </div>
                <div className={bottomPanelClass} onClick={checkoutClick} >
                    Check-out
                </div>
            </div>
        );
    }
}
