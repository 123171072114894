import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { getAuthHeaders } from './api-authorization/getAuthHeaders';

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== "";
}

function GetProjectAddressDataSource(id, handleFetchException) {
    if (id != null) {
        return new DataSource({
            store: new CustomStore({
                key: "ID",
                load: async (loadOptions) => {
                    let params = "?";
                    [
                        "skip",
                        "take",
                        "sort",
                        "filter",
                        "searchExpr",
                        "searchOperation",
                        "searchValue",
                        "group"
                    ].forEach(function (i) {
                        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                            if (i === 'searchValue' && loadOptions[i]) {
                                var tab = loadOptions.searchExpr.toString().split(',');
                                let filter = [[tab[0], loadOptions.searchOperation, loadOptions[i]]];

                                for (var j = 1; j < tab.length; j++) {
                                    filter.push('or');
                                    filter.push([tab[j], loadOptions.searchOperation, loadOptions[i]])
                                }
                                params += `filter=${JSON.stringify(filter)}&`
                            }
                            else {
                                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                            }
                        }
                    });

                    params += `Project=` + id;

                    const headers = await getAuthHeaders();
                    const requestOptions = {
                        method: 'GET',
                        headers: headers
                    };
                    return fetch(window.config.apiURL + "ProjectAddress/GetProjectAddresses" + params, requestOptions)
                        .then(response => {
                            if (!response.ok) { throw response }
                            return response.json()
                        })
                        .catch(response => {
                            handleFetchException(response);
                        });
                },
                byKey: async (key) => {

                    const headers = await getAuthHeaders();
                    const requestOptions = {
                        method: 'GET',
                        headers: headers
                    };
                    return fetch(window.config.apiURL + "ProjectAddress/GetProjectAddress?id=" + key, requestOptions)
                        .then(response => {
                            if (!response.ok) { throw response }
                            return response.json()
                        })
                        .catch(response => {
                            handleFetchException(response);
                        });
                }
            })
        });
    }
    else {
        console.debug("GetProjectAddressDataSource: null dataset");
        return null;
    }
}

export default GetProjectAddressDataSource;