//function component - recomended when we do not use state
const ErrorComponent = (props) => {
    return (
            <div className="error-panel">
                <div className="error-content-panel">
                    <div className="error-header">Server returned following errors:</div>
                    
                    {props.postResult?.Items?.map((d, i) =>
                        (
                            <div className="custom-error-label" key={i}>*{d.Message}</div>
                        ))}
                </div>
            </div>
    );
}
export default ErrorComponent;