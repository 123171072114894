import Spinner from 'react-bootstrap/Spinner';
import React, { useState } from 'react';
import { useMsal } from "@azure/msal-react";
import loginLogo from '../images/microsoft.png'; 
import './Login.css';

export const Login = () => {
    const [clicked, setClicked] = useState(false);
    const { instance } = useMsal();

    const handleLogin = (e) => {
        e.preventDefault();

        if (!clicked) {
            setClicked(true);
            instance.loginRedirect();
        }
    }

    if (clicked)
        return (
          <div id="LoginContent">
            <Spinner animation="border" variant="primary">
            </Spinner>
          </div>
        );
    else
        return (
            <div id="LoginContent">
                <a onClick={(e) => handleLogin(e)} href="/"><img src={loginLogo} alt="Vertex logo"></img>
                    <b id="LoginHeader">Sign in using Microsoft</b>
                </a>
            </div>
        );
}
