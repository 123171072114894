import { useCallback, useEffect, useState, useReducer } from "react";
import { Popup, Position, Animation } from 'devextreme-react/popup';
import { Logout } from './api-authorization/Logout.js';
import { useMsal } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import  imgPlaceholder  from "./images/user.png"
import { getUserImg } from "./getUserImg";



function reducer(state, action) {
    switch (action.type) {
        case 'setFullName':
            return {...state, fullName: action.payload.fullName }
        case 'setFName':
            return { ...state, name: action.payload.fullName.split(' ')[0] }
        case 'setEmail':
            return {...state, email: action.payload.email }
        default:
            return state
    }
}

export const WelcomeName = () => {
    const { accounts } = useMsal();
    const [photo, setPhoto] = useState(null);
    const [isPopupVisible, setIsPopupVisible] = useState(false);


    const [userInfo, dispatchUserInfo] = useReducer(reducer, {
        fullName: "",
        name: "",
        email: ""
    })

    const fetchUserInfos = useCallback(async () => {
        if (accounts.length > 0) {
            dispatchUserInfo({ type: "setFullName", payload: { fullName: accounts[0].name}})
            dispatchUserInfo({ type: "setFName", payload: { fullName: accounts[0].name }})
            dispatchUserInfo({ type: "setEmail", payload: { email: accounts[0].username}})

            Sentry.setUser({ email: accounts[0].username });
            let response = await getUserImg();

            if (response) {
                setPhoto(response);
            } else {                
                setPhoto(imgPlaceholder)
            }
        }
    }, [accounts])

    useEffect(() => {           
        fetchUserInfos()
    }, [fetchUserInfos]);


    function showInfoHandler() {
        setIsPopupVisible(true);
    }

    function hideInfo() {
        setIsPopupVisible(false);
    }

    const animationOption = {
        type: 'fadeIn',
        duration: 400,
    }

    const animationOption2 = {
        type: 'fadeOut',
        duration: 400,
    }    

    if (userInfo.name) {
        return (
            <>
                <div className="user-container" onClick={ showInfoHandler }>
                    <img src={photo ? photo : ''} className="user-img" alt="User profile" />                    
                    <div className="dx-field-label user-name">Hello {userInfo.name}</div>
                </div>

                    <Popup
                    visible={isPopupVisible}
                    onHiding={hideInfo}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showCloseButton={true}
                    showTitle={false}
                    title="Account info"
                    container=".header-right"
                    width={300}
                    height={180}
                >

                    <Animation
                        hide={animationOption2}
                        show={animationOption}
                    />
                    <Position
                        at="left"
                        my="top"
                        of=".header-right"
                    />

                    <div className="popup-layout">
                        <img className="popup-user-img" src={photo ? photo : ''} alt="User profile" width={75} height={75}/>
                        <div>
                            <p className="popup-user-name">{userInfo.fullName}</p>
                            <p className="popup-user-email">{ userInfo.email }</p>
                        </div>
                    </div>
                    <Logout/>                      
                 </Popup>
                </>
            );
    } else {
        return null;
    }
};
