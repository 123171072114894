import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { Lookup } from 'devextreme-react/lookup';
import {
    Validator,
    RequiredRule,
} from 'devextreme-react/validator';
import { getAuthHeaders } from '../api-authorization/getAuthHeaders';

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== "";
}

const OfficeComponent = (props) => {
    const lookupOfficeDataSource = new DataSource({
        store: new CustomStore({
            key: "ID",
            load: async (loadOptions) => {
                let params = "?";
                [
                    "skip",
                    "take",
                    "sort",
                    "filter",
                    "searchExpr",
                    "searchOperation",
                    "searchValue",
                    "group"
                ].forEach(function (i) {
                    if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                        if (i === 'searchValue' && loadOptions[i]) {
                            let filter = [[loadOptions.searchExpr, loadOptions.searchOperation, loadOptions[i]]]
                            params += `filter=${JSON.stringify(filter)}&`
                        }
                        else
                            params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
                params = params.slice(0, -1);
                const headers = await getAuthHeaders('application/json');

                const requestOptions = {
                    method: 'GET',
                    headers: headers
                };
                return fetch(window.config.apiURL + "Office/GetOffices" + params, requestOptions)
                    .then(response => {
                        if (!response.ok) { throw response }
                        return response.json()
                    })
                    .catch(response => {
                        props.handleFetchException(response);
                    });

            },
            byKey: async (key) => {
                const headers = await getAuthHeaders('application/json');

                const requestOptions = {
                    method: 'GET',
                    headers: headers
                };
                return fetch(window.config.apiURL + "Office/GetOffice?id=" + key, requestOptions)
                    .then(response => {
                        if (!response.ok) { throw response }
                        return response.json()
                    })
                    .catch(response => {
                        props.handleFetchException(response);
                    });
            }
        })
    });

    return (<div className="dx-field">
        <div className="custom-label">Office:</div>
        <div className="custom-value">
            <Lookup
                name="Office"
                valueExpr="ID"
                displayExpr="Name"
                placeholder="Select an office..."
                searchExpr="Name"
                searchMode="contains"
                value={props.officeValue}
                onValueChanged={(event) => props.onValueChanged(event)}
                showClearButton={true}
                dataSource={lookupOfficeDataSource}>
                <Validator>
                    <RequiredRule message="Office is required" />
                </Validator>
            </Lookup>
        </div>
    </div>
    );
}

export default OfficeComponent;