import React, { useState, useEffect } from 'react';
import './CheckInOut.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Tabs from 'devextreme-react/tabs';
import Button from 'devextreme-react/button';
import DateBox from 'devextreme-react/date-box';
import ErrorComponent from './check-in-out/ErrorComponent.js';
import queryString from 'query-string'
import OfficeComponent from './check-in-out/OfficeComponent.js';
import ProjectComponent from './check-in-out/ProjectComponent.js';
import ProjectAddressComponent from './check-in-out/ProjectAddressComponent.js';
import { Redirect } from "react-router-dom";
import TextArea from 'devextreme-react/text-area';
import { NumberBox } from 'devextreme-react/number-box';
import { getAuthHeaders } from './api-authorization/getAuthHeaders';
import GetProjectAddressDataSource from "./getAddresses";

import {
    Validator,
    RequiredRule,
} from 'devextreme-react/validator';

export const tabs = [
    {
        id: 0,
        text: 'Office',
        icon: 'home'
    },
    {
        id: 1,
        text: 'Project / Site location',
        icon: 'toolbox'
    }
];

export function Checkin({ handleFetchException }) {
    const [office, setOffice] = useState(null)
    const [project, setProject] = useState(null)
    const [projectAddress, setProjectAddress] = useState(null)
    const [billableHours, setBillableHours] = useState(null)
    const [checkinDate, setCheckinDate] = useState(new Date())
    const [notes, setNotes] = useState('')
    const [redirect, setRedirect] = useState(null)
    const [hasError, setHasError] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [postResult, setPostResult] = useState(null)
    const [items, setItems] = useState([])
    const [estimatedCheckoutDate, setEstimatedCheckoutDate] = useState(new Date());

    useEffect(() => {
        getParameters();
    }, [])

    async function getState(projectAddress) {
        const headers = await getAuthHeaders('application/json');
        const requestOptions = {
            method: 'GET',
            headers: headers
        };
        fetch(window.config.apiURL + "ProjectAddress/Get/" + projectAddress, requestOptions)
            .then(response => {
                if (!response.ok) { throw response }
                return response.json()
            })
            .then(data => {
                setProject(data.Project)
                setProjectAddress(data.ID)
                setSelectedIndex(1)
            })
            .catch(response => {
                handleFetchException(response);
            });
    }

    function getParameters() {
        const queryStringParams = queryString.parse(window.location.search);
        var office = parseInt(queryStringParams['office']);
        if (office) {
            setOffice(office);
        }
        var projectAddress = parseInt(queryStringParams['projectaddress']);
        if (projectAddress) {
            getState(projectAddress);
        }
    }

    function backOnClick() {
        setRedirect("/")
    }

    async function saveOnClick(event) {
        let result = event.validationGroup.validate();
        if (result.isValid) {
            const headers = await getAuthHeaders('application/json');
            const requestOptions = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(
                    {
                        Office: office,
                        Project: project,
                        ProjectAddress: projectAddress,
                        BillableHours: billableHours,
                        CheckinDate: checkinDate,
                        EstimatedCheckoutDate: estimatedCheckoutDate,
                        Notes: notes
                    }
                )
            };
            fetch(window.config.apiURL + "PersonnelAttendance/Put", requestOptions)
                .then(response => {
                    if (!response.ok) { throw response }
                    return response.json()
                })
                .then(data => {
                    if (data.HasError) {
                        setHasError(true)
                        setPostResult(data.Validation)
                    }
                    else
                        setRedirect("/")
                })
                .catch(response => {
                    handleFetchException(response);
                });
        }
    }

    function onTabsSelectionChanged(args) {
        if (args.name === 'selectedIndex') {
            setSelectedIndex(args.value)

            if (args.value === 1) {
                setOffice(null)
            }
            else {
                setProject(null)
                setProjectAddress(null)
            }
        }
    }

    function onProjectChanged(e) {
        setProject(e.value)

        GetProjectAddressDataSource(e.value, handleFetchException)?.store().load().then(response => {

            if (response.length > 1) {
                setItems(response)
            } else {
                setItems(response)
                setProjectAddress(response[0].ID)
            }
        })
    }

    function onProjectAddressChanged(e) {
        setProjectAddress(e.value);
    }

    function onOfficeChanged(e) {
        setOffice(e.value);
    }

    function onCheckinDateChanged(e) {
        setCheckinDate(e.value);
    }

    function onEstimatedCheckoutDateChanged(e) {
        setEstimatedCheckoutDate(e.value);
    }

    function onBillableHoursChanged(e) {
        setBillableHours(e.value)
    }

    function onNotesChanged(e) {
        setNotes(e.value);
    }

    function displayTabContent() {
        if (selectedIndex === 0) {
            return (
                <div>
                    <OfficeComponent onValueChanged={onOfficeChanged} officeValue={office ? office : null} handleFetchException={handleFetchException} />
                </div>
            );
        } else if (selectedIndex === 1) {
            return (
                <div>
                    <ProjectComponent onValueChanged={onProjectChanged} projectValue={project ? project : null} handleFetchException={handleFetchException} />
                    <ProjectAddressComponent items={items} onValueChanged={onProjectAddressChanged} ProjectID={project} projectAddressValue={projectAddress ? projectAddress : null} handleFetchException={handleFetchException} />
                </div>
            );
        }
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <fieldset className="form-field">
            <legend className="roll-call-legend">Check-in</legend>

            <div className="dx-field">
                <div className="custom-label">Check-in:</div>
                <div className="custom-value">
                    <DateBox
                        value={checkinDate}
                        type="datetime"
                        onValueChanged={(event) => onCheckinDateChanged(event)} >
                        <Validator>
                            <RequiredRule message="Date is required" />
                        </Validator>
                    </DateBox>
                </div>
            </div>
            <div className="dx-field">
                <div className="custom-label">Est. check-out:</div>
                <div className="custom-value">
                    <DateBox type="datetime"
                        value={estimatedCheckoutDate}
                        onValueChanged={(event) => onEstimatedCheckoutDateChanged(event)} />
                </div>
            </div>
            <div className="dx-field">
                <div className="custom-label"></div>
                <div className="custom-value">
                    <Tabs
                        dataSource={tabs}
                        selectedIndex={selectedIndex}
                        onOptionChanged={onTabsSelectionChanged}
                    />
                </div>
            </div>
            <div className="dx-field">
                {displayTabContent()}
            </div>
            <div className="dx-field">
                <div className="custom-label">Billable hours:</div>
                <div className="custom-value">
                    <NumberBox
                        value={billableHours}
                        min={0}
                        max={24}
                        showSpinButtons={true}
                        onValueChanged={(event) => onBillableHoursChanged(event)}
                    />
                </div>
            </div>
            <div className="dx-field">
                <div className="custom-label">Notes:</div>
                <div className="custom-value">
                    <TextArea
                        placeholder="Type a text here..."
                        value={notes}
                        onValueChanged={(event) => onNotesChanged(event)}
                        height="85">
                    </TextArea>
                </div>
            </div>
            <div className="dx-field">
                <div className="section-navigation-panel">
                    <div className="buttons-group">
                        <Button
                            width={120}
                            text="Go Back"
                            className="post-button-text-style"
                            stylingMode="text"
                            onClick={backOnClick}
                        />
                        <Button
                            width={120}
                            height={34}
                            text="Save"
                            className="post-button-style"
                            onClick={saveOnClick}
                        />
                    </div>
                </div>
            </div>
            {hasError === true ?
                (
                    <ErrorComponent postResult={postResult ? postResult : ''} />
                ) : ("")}
        </fieldset>
    );
}
