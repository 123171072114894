import React, { useEffect, useState } from 'react';
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { Lookup } from 'devextreme-react/lookup';
import { getAuthHeaders } from '../api-authorization/getAuthHeaders';

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== "";
}

const ProjectComponent = (props) => {
    const [items, setItems] = useState();

    useEffect(() => {
        setItems(lookupProjectDataSource());
    }, [])

    function lookupProjectDataSource() {
        return new DataSource({
            store: new CustomStore({
                key: "ID",
                load: async (loadOptions) => {
                    let params = "?";
                    [
                        "skip",
                        "take",
                        "sort",
                        "filter",
                        "searchExpr",
                        "searchOperation",
                        "searchValue",
                        "group"
                    ].forEach(function (i) {
                        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                            if (i === 'searchValue' && loadOptions[i]) {
                                var tab = loadOptions.searchExpr.toString().split(',');
                                let filter = [[tab[0], loadOptions.searchOperation, loadOptions[i]]];

                                for (var j = 1; j < tab.length; j++) {
                                    filter.push('or');
                                    filter.push([tab[j], loadOptions.searchOperation, loadOptions[i]])
                                }
                                params += `filter=${JSON.stringify(filter)}&`
                            }
                            else {
                                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                            }
                        }
                    });
                    params = params.slice(0, -1);
                    const headers = await getAuthHeaders('application/json');
                    const requestOptions = {
                        method: 'GET',
                        headers: headers
                    };
                    return fetch(window.config.apiURL + "Project/GetProjects" + params, requestOptions)
                        .then(response => {
                            if (!response.ok) { throw response }
                            return response.json()
                        })
                        .catch(response => {
                            props.handleFetchException(response);
                        });
                },
                byKey: async (key) => {
                    const headers = await getAuthHeaders('application/json');
                    const requestOptions = {
                        method: 'GET',
                        headers: headers
                    };
                    return fetch(window.config.apiURL + "Project/GetProject?id=" + key, requestOptions)
                        .then(response => {
                            if (!response.ok) { throw response }
                            return response.json()
                        })
                        .catch(response => {
                            props.handleFetchException(response);
                        });
                }
            })
        });
    }

    return (<div className="dx-field">
        <div className="custom-label">Project:</div>
        <div className="custom-value">
            <Lookup
                name="Project"
                valueExpr="ID"
                displayExpr="DisplayName"
                searchMode="contains"
                placeholder="Select a project..."
                searchExpr="Name,ProjectNo,ProposalNo"
                dataSource={items ? items : ''}
                value={props.projectValue}
                onValueChanged={(event) => props.onValueChanged(event)}
                showClearButton={true}>
            </Lookup>
        </div>
    </div>
    );
}

export default ProjectComponent;
