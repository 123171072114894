import React from 'react';

export function InvalidOperation({ errorResponseObject, errorResponseMessage }) {

    function displayMessages() {
        if (errorResponseObject != null) {
            return (
                errorResponseObject.Items.map((item, index) =>
                (
                    <div className="custom-error-label" key={index}>*{item.Message}</div>
                )
                )
            );
        }
        else if (errorResponseMessage) {
            return (
                <div className="custom-error-label" key={0}>*{errorResponseMessage}</div>
            );
        }
    }

    return (
        <div>
            <div className="error-panel">
                <div className="error-content-panel">
                    <div className="error-header">Server returned following errors:</div>
                    {displayMessages()}
                </div>
            </div>
        </div>
    );
}

