import { getGraphAuthHeaders } from "./api-authorization/getAuthHeaders";
import { graphConfig } from '../authConfig';

export const getUserImg = async () => {
    const headers = await getGraphAuthHeaders('image/jpeg');

    const options = {
        method: "GET",       
        headers: headers,
    };

    try {
        const response = await fetch(graphConfig.graphMePhotoEndpoint, options)

        if (!response.ok) {
            console.error('HTTP error! status: ', response.status);
            throw 'HTTP error! status: ' + response.status;
        }

        const data = await response.blob()
        if (data !== null) {
            window.URL = window.URL || window.webkitURL;
            return window.URL.createObjectURL(data);
        }
    } catch (error) {
        console.debug(error);
    }
}
