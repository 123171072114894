import React from 'react';
import { Lookup } from 'devextreme-react/lookup';
import {
    Validator,
    RequiredRule,
} from 'devextreme-react/validator';


const ProjectAddressComponent = (props) => {

    return (<div className="dx-field">
        <div className="custom-label">Site:</div>
        <div className="custom-value">
            <Lookup
                name="ProjectAddress"
                valueExpr="ID"
                displayExpr="FullAddress"
                searchMode="contains"
                placeholder="Select a site location..."
                searchExpr="Name,Address1,Address2,State,City,Country"
                dataSource={props.items}
                onValueChanged={(event) => props.onValueChanged(event)}                
                value={props.projectAddressValue}
                showClearButton={true}>
                <Validator>
                    <RequiredRule message="Project Site Location is required" />
                </Validator>
            </Lookup>
        </div>
    </div>
    );
}

export default ProjectAddressComponent;


