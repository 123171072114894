import { loginRequest, graphRequest } from "../../authConfig";
import { msalInstance } from "../../index";

export async function getAuthHeaders(contentType) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", contentType);
    headers.append("Accept", contentType);

    return headers;
}

export async function getGraphAuthHeaders(contentType) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        ...graphRequest,
        account: account
    });

    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", contentType);
    headers.append("Accept", contentType);

    return headers;
}