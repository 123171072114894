import React, { useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { WelcomeName } from './WelcomeName.js';
import { Login } from './api-authorization/Login.js';
import { Route } from 'react-router';
import { Checkin } from './Checkin';
import { Checkout } from './Checkout';
import { InvalidOperation } from './InvalidOperation';
import { Home } from './Home';
import logo from './images/VertexLogo.png';
import * as Sentry from "@sentry/react";
import './Layout.css';

function Layout() {
    const [errorResponseObject, setErrorResponseObject] = useState(null);
    const [errorResponseMessage, setErrorResponseMessage] = useState('');

    function handleFetchException(response) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(data => {
                try {
                    console.debug("handleFetchException: json " + JSON.stringify(data));

                    if (data.Message) {
                        setErrorResponseMessage(data.Message);
                        Sentry.captureException(new Error(JSON.stringify(data)));
                    }
                    else if (data.Validation) {
                        setErrorResponseObject(data.Validation);
                        Sentry.captureException(new Error(JSON.stringify(data)));
                    }
                    else if (response.statusText) {
                        setErrorResponseMessage(response.statusText);
                        Sentry.captureException(new Error(JSON.stringify(response.statusText)));
                    }
                }
                catch (error) {
                    Sentry.captureException(error);
                }
            });
        } else {
            return response.text().then(text => {
                try {
                    console.debug("handleFetchException: test " + text);
                    setErrorResponseMessage(text);
                    Sentry.captureException(new Error(text));
                }
                catch (error) {
                    Sentry.captureException(error);
                }
            });
        }
    }

    return (
        <div className="layout-container">
            <AuthenticatedTemplate>
                <div>
                    <div className="header">
                        <div className="header-container">
                            <a href="https://vertexeng.com/" title="The Vertex Companies, Inc." rel="home"><img className="header-logo" src={logo} alt="The Vertex Companies, Inc." /></a>
                            <div className="header-right">
                                <WelcomeName handleFetchException={handleFetchException} />
                            </div>
                        </div>
                    </div>
                    {errorResponseObject != null || errorResponseMessage ? (
                        <InvalidOperation errorResponseObject={errorResponseObject} errorResponseMessage={errorResponseMessage} />
                    ) : (
                        <>
                            <Route exact path='/' render={() => <Home handleFetchException={handleFetchException} />} />
                            <Route path='/checkin' render={() => <Checkin handleFetchException={handleFetchException} />} />
                            <Route path='/checkout' render={() => <Checkout handleFetchException={handleFetchException} />} />
                        </>
                    )}
                </div>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <div id="login-container">
                    <div className="header-center">
                        <a href="https://vertexeng.com/" title="The Vertex Companies, Inc." rel="home"><img src={logo} alt="The Vertex Companies, Inc." /></a>
                    </div>
                    <Login />
                </div>
            </UnauthenticatedTemplate>
        </div>
    )
}


export default Layout;