import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from 'devextreme-react/button';

export const Logout = () => {
    const { instance } = useMsal();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleLogout = () => {
        setAnchorEl(null);

        instance.logoutRedirect();
    }

    return (
        <Button
            text="Sign out"
            className="logout-button"
            onClick={() => handleLogout()}
        />
    );
}
