import React, { useState, useEffect } from 'react';
import './CheckInOut.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Tabs from 'devextreme-react/tabs';
import Button from 'devextreme-react/button';
import DateBox from 'devextreme-react/date-box';
import ErrorComponent from './check-in-out/ErrorComponent.js';
import queryString from 'query-string'
import OfficeComponent from './check-in-out/OfficeComponent.js';
import ProjectComponent from './check-in-out/ProjectComponent.js';
import ProjectAddressComponent from './check-in-out/ProjectAddressComponent.js';
import { Redirect } from "react-router-dom";
import TextArea from 'devextreme-react/text-area';
import { NumberBox } from 'devextreme-react/number-box';
import { getAuthHeaders } from './api-authorization/getAuthHeaders';
import GetProjectAddressDataSource from "./getAddresses";

import {
    Validator,
    RequiredRule,
} from 'devextreme-react/validator';

export const tabs = [
    {
        id: 0,
        text: 'Office',
        icon: 'home'
    },
    {
        id: 1,
        text: 'Project / Site location',
        icon: 'toolbox'
    }
];

export function Checkout({ handleFetchException }) {   

    const [id, setId] = useState(null)
    const [office, setOffice] = useState(null)
    const [project, setProject] = useState(null)
    const [projectAddress, setProjectAddress] = useState(null)
    const [billableHours, setBillableHours] = useState(null)
    const [checkinDate, setCheckinDate] = useState(new Date())
    const [checkoutDate, setcheckoutDate] = useState(new Date());
    const [notes, setNotes] = useState('')
    const [redirect, setRedirect] = useState(null)
    const [hasError, setHasError] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [postResult, setPostResult] = useState(null)
    const [items, setItems] = useState([])

    useEffect(() => {
        getParameters();
    }, [])

    async function getPersonnelAttendance(checkinID) {
        const headers = await getAuthHeaders('application/json');
        const requestOptions = {
            method: 'GET',
            headers: headers
        };
        fetch(window.config.apiURL + "PersonnelAttendance/GetPersonnelAttendance/" + checkinID, requestOptions)
            .then(response => {
                if (!response.ok) { throw response }
                return response.json()
            })
            .then(data => {
                if (data.HasError) {
                    setHasError(true);
                    setPostResult(data.Validation)
                }
                else {                    
                    setProject(data.Data.Project)
                    setProjectAdressFromDataSource(data.Data.Project)
                    setOffice(data.Data.Office)                    
                    setBillableHours(data.Data.BillableHours)
                    setCheckinDate(new Date(data.Data.CheckinDate))
                    setNotes(data.Data.Notes)
                    setId(data.Data.ID)
                    setSelectedIndex((data.Data.ProjectAddress != null ? 1 : 0))
                }
            })
            .catch(response => {
                handleFetchException(response);
            });
    }

    async function getPersonnelAttendanceByOffice(officeID) {
        const headers = await getAuthHeaders('application/json');
        const requestOptions = {
            method: 'GET',
            headers: headers
        };
        fetch(window.config.apiURL + "PersonnelAttendance/GetPersonnelAttendanceByOffice/" + officeID, requestOptions)
            .then(response => {
                if (!response.ok) { throw response }
                return response.json()
            })
            .then(data => {
                if (data.HasError) {
                    setHasError(true);
                    setPostResult(data.Validation)
                }
                else {
                    setId(data.ID)
                    setBillableHours(data.BillableHours)
                    setNotes(data.Notes)
                    
                }
            })
            .catch(response => {
                handleFetchException(response);
            });
    }

    function getParameters() {
        const queryStringParams = queryString.parse(window.location.search);
        var checkinID = parseInt(queryStringParams['ID']);
        if (checkinID) {
            getPersonnelAttendance(checkinID);
        } else {
            var office = parseInt(queryStringParams['office']);
            if (office) {
                setOffice(office)
                getPersonnelAttendanceByOffice(office);
            }
            else {
                setOffice(null)
            }
        }
    }

    function backOnClick(event) {
        setRedirect("/")
    }

    async function saveOnClick(event) {
        let result = event.validationGroup.validate();
        if (result.isValid) {
            const headers = await getAuthHeaders('application/json');
            const requestOptions = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    Office: office,
                    Project: project,
                    ProjectAddress: projectAddress,
                    BillableHours: billableHours,
                    CheckinDate: checkinDate,
                    CheckoutDate: checkoutDate,
                    Notes: notes,
                    ID: id
                })
            };
            fetch(window.config.apiURL + "PersonnelAttendance/SaveOrUpdate", requestOptions)
                .then(response => {
                    if (!response.ok) { throw response }
                    return response.json()
                })
                .then(data => {
                    if (data.HasError) {
                        setHasError(true);
                        setPostResult(data.Validation);
                    }
                    else
                        setRedirect("/");
                })
                .catch(console.error);
        }
    }

    function onTabsSelectionChanged(args) {
        if (args.name === 'selectedIndex') {
            setSelectedIndex(args.value)
            if (args.value === 1) {
                    setOffice(null)
            } else {                
                setProject(null)
                setProjectAddress(null)                
            }
        }
    }

    function onProjectChanged(e) {        
        setProject(e.value)
        setProjectAdressFromDataSource(e.value)
    }

    function setProjectAdressFromDataSource(projectName) {
        GetProjectAddressDataSource(projectName, handleFetchException)?.store().load().then(response => {

            if (response.length > 1) {
                setItems(response)
            } else {
                setItems(response)
                setProjectAddress(response[0].ID)
            }
        })
    }
   

    function onProjectAddressChanged(e) {
        setProjectAddress(e.value);
    }

    function onOfficeChanged(e) {
        setOffice(e.value);
    }

    function onCheckinDateChanged(e) {
        setCheckinDate(e.value);
    }

    function onCheckoutDateChanged(e) {
        setcheckoutDate(e.value);
    }

    function onBillableHoursChanged(e) {
        setBillableHours(e.value)
    }

    function onNotesChanged(e) {
        setNotes(e.value);
    }

    function displayTabContent() {
        if (selectedIndex === 0) {
            return (
                <div>
                    <OfficeComponent onValueChanged={onOfficeChanged} officeValue={office ? office : null} handleFetchException={handleFetchException} />
                </div>
            );
        } else if (selectedIndex === 1) {
            return (
                <div>
                    <ProjectComponent onValueChanged={onProjectChanged} projectValue={project ? project : null} handleFetchException={handleFetchException} />
                    <ProjectAddressComponent items={items} onValueChanged={onProjectAddressChanged} ProjectID={project} projectAddressValue={projectAddress ? projectAddress : null} handleFetchException={handleFetchException} />
                </div>
            );
        }
    }

    function displayCheckinContent() {
        if (id === null) {
            return (
                <div>
                    <div className="dx-field">
                        <div className="custom-label">Billable hours:</div>
                        <div className="custom-value">
                            <NumberBox
                                value={billableHours}
                                min={0}
                                max={24}
                                showSpinButtons={true}
                                onValueChanged={(event) => onBillableHoursChanged(event)}
                            />
                        </div>
                    </div>
                    <div className="dx-field">
                        <div className="custom-label">Notes:</div>
                        <div className="custom-value">
                            <TextArea
                                value={notes}
                                placeholder="Type a text here..."
                                onValueChanged={(event) => onNotesChanged(event) }
                                height="100">
                            </TextArea>
                        </div>
                    </div>
                </div>
            );
        }
        else
            return (
                <div> </div>
            );
    }


    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <fieldset className="form-field">
            <legend className="roll-call-legend">Check-out</legend>
            <div className="dx-field">
                <div className="custom-label">Check-in:</div>
                <div className="custom-value">
                    <DateBox value={checkinDate}
                        value={checkinDate}
                        type="datetime"
                        disabled={id > 1}
                        onValueChanged={(event) => onCheckinDateChanged(event)}
                    >
                        <Validator>
                            <RequiredRule message="Date is required" />
                        </Validator>
                    </DateBox>
                </div>
            </div>
            <div className="dx-field">
                <div className="custom-label">Check-out:</div>
                <div className="custom-value">
                    <DateBox
                        type="datetime"
                        value={checkoutDate}
                        onValueChanged={(event) => onCheckoutDateChanged(event)} >                        
                        <Validator>
                            <RequiredRule message="Date is required" />
                        </Validator>
                    </DateBox>
                </div>
            </div>
            <div className="dx-field">
                <div className="custom-label"></div>
                <div className="custom-value">
                    <Tabs
                        dataSource={tabs}
                        selectedIndex={selectedIndex}
                        onOptionChanged={onTabsSelectionChanged}
                    />
                </div>
            </div>
            <div className="dx-field">
                {displayTabContent()}
            </div>
            <div className="dx-field">
                {displayCheckinContent()}
            </div>
            <div className="dx-field">
                <div className="section-navigation-panel">
                    <Button
                        width={120}
                        text="Go Back"
                        type="success"
                        className="post-button-text-style"
                        stylingMode="text"
                        onClick={backOnClick}
                    />
                    <Button
                        width={120}
                        text="Save"
                        type="success"
                        className="post-button-style"
                        stylingMode="outlined"
                        onClick={saveOnClick}
                    />
                </div>
            </div>
            {hasError === true ?
                (
                    <ErrorComponent postResult={postResult ? postResult : ''} />
                ) : ("")}
        </fieldset>
    );
}