import React from 'react';
import { MsalProvider } from "@azure/msal-react";
import Layout from './components/Layout';
import './custom.css'

function App({ pca }) {
/*
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const history = useHistory();
    const navigationClient = new CustomNavigationClient(history);
    pca.setNavigationClient(navigationClient);
*/

    return (
        <MsalProvider instance={pca}>
            <Layout pca={pca}></Layout>
        </MsalProvider>
    );
}

export default App;
/*
export default class App extends Component {
  static displayName = App.name;

    render() {
        return (<Layout></Layout>);
    }
}
*/